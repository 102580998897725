'use client';

import React from 'react';
import { useNotifications } from '@/hooks/useNotifications';
import { ImportedEntriesNotification } from '@/components/Notification/imported-entries/ImportedEntriesNotification';
import { BankAccountMissingNotification } from '@/components/Notification/bank-account-missing/BankAccountMissingNotification';
import { UnpaidEntriesNotification } from '@/components/Notification/unpaid-entries/UnpaidEntriesNotification';
import { PendingEmailChangeNotification } from '@/components/Notification/pending-email-change/PendingEmailChangeNotification';
import { MissingIdentificationNotification } from '@/components/Notification/missing-identification/MissingIdentificationNotification';
import { UnreadMessageNotification } from '@/components/Notification/unread-message/UnreadMessageNotification';
import { VehicleRegistrationNotification } from '@/components/Notification/vehicle-registration/VehicleRegistrationNotification';
import { SystemMessageNotification } from '@/components/Notification/system-notification/SystemMessageNotification';
import { DsaComplianceMissingNotification } from '@/components/Notification/dsa-compliance-missing/DsaComplianceMissingNotification';
import { EndedEntriesNotification } from '@/components/Notification/ended-entries/EndedEntriesNotification';

export function Notifications() {
  const { notifications } = useNotifications();

  return (
    <>
      <UnreadMessageNotification latestUnreadMessage={notifications.data?.latestUnreadMessage} />
      <SystemMessageNotification systemNotification={notifications.data?.systemNotification} />
      <ImportedEntriesNotification importedEntries={notifications.data?.importedEntries} />
      <BankAccountMissingNotification isBankAccountMissing={notifications.data?.isBankAccountMissing} />
      <UnpaidEntriesNotification entries={notifications.data?.unpaidEntries} />
      <EndedEntriesNotification
        consumerEntryNeedsApprovalBefore={notifications.data?.consumerEntryNeedsApprovalBefore}
      />
      <PendingEmailChangeNotification pendingEmailChange={notifications.data?.pendingEmailChange} />
      <MissingIdentificationNotification isIdentificationMissing={notifications.data?.isIdentificationMissing} />
      <VehicleRegistrationNotification entries={notifications.data?.vehicleRegistrations} />
      <DsaComplianceMissingNotification isDsaComplianceMissing={notifications.data?.isDsaComplianceMissing} />
    </>
  );
}
