import React from 'react';
import { Box, Button, Heading, LinkBox, LinkOverlay, Table, Text } from '@mezzoforte/forge';
import Link from '@/components/Link/Link';
import { Container } from '@/components/Container/Container';

export interface VehicleRegistrationEntry {
  readonly publicId: number;
  readonly slug: string;
  readonly title: string;
}

export interface VehicleRegistrationNotificationProps {
  readonly entries?: VehicleRegistrationEntry[] | null;
}

export function VehicleRegistrationNotification({ entries }: VehicleRegistrationNotificationProps) {
  if (!entries || entries.length === 0) {
    return null;
  }

  return (
    <Box
      py={3}
      backgroundColor="backgroundSuccess"
      data-test="vehicle-registration-notification"
    >
      <Container>
        <Heading
          variant="h4"
          as="h2"
        >
          Hei! Meillä olisi vielä pari kysymystä näiden kohteiden rekisteröintiä varten.
        </Heading>
        <Text mt={2}>
          Pyydämme sinua syöttämään sen henkilön tai yrityksen tiedot, jonka nimiin kohde halutaan rekisteröidä.
        </Text>
        <Table
          data-test="vehicle-registration-table"
          size="compact"
          mt={3}
          data={entries.map((entry) => ({
            title: <Link href={`/kohde/${entry.publicId}/${entry.slug}`}>{entry.title}</Link>,
            action: (
              <LinkBox>
                <Button variant="primary-lists">
                  <LinkOverlay
                    as={Link}
                    href={`/kohteet/${entry.publicId}/rekisterointi`}
                  >
                    Syötä tiedot
                  </LinkOverlay>
                </Button>
              </LinkBox>
            ),
          }))}
          columns={[
            { Header: 'Kohde', accessor: 'title' },
            { Header: 'Rekisteröintitiedot', accessor: 'action' },
          ]}
          customCellStyles={{
            // we need !important because <Table> sets flex property inline
            0: { flex: '10 0 auto !important' },
            1: { textAlign: 'right', flex: '1 1 150px !important' },
          }}
        />
      </Container>
    </Box>
  );
}
