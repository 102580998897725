export class Deferred<T> {
  public readonly promise: Promise<T>;

  public resolve: (value: T | PromiseLike<T>) => void = () => {};

  public reject: (reason?: unknown) => void = () => {};

  public constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
