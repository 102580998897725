import React, { useState } from 'react';
import { Box, Button, Flex, Heading, Text } from '@mezzoforte/forge';
import { Container } from '@/components/Container/Container';
import { BankAccountMissingModal } from '@/components/Notification/bank-account-missing/BankAccountMissingModal';
import { useCompanySelection } from '@/hooks/useCompanySelection';

export interface BankAccountMissingNotificationProps {
  readonly isBankAccountMissing?: boolean;
}

export function BankAccountMissingNotification({ isBankAccountMissing }: BankAccountMissingNotificationProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedCompany } = useCompanySelection();

  const companyId = selectedCompany?.id;
  if (!isBankAccountMissing || !companyId) {
    return null;
  }

  return (
    <>
      <BankAccountMissingModal
        isOpen={isModalOpen}
        companyId={companyId}
        onDismiss={() => {
          setIsModalOpen(false);
        }}
        isConsumerCompany={selectedCompany.isConsumer}
      />
      <Box
        py={3}
        backgroundColor="backgroundWarning"
        data-test="bank-account-missing-notification"
      >
        <Container>
          <Heading
            variant="h4"
            mb={3}
          >
            {selectedCompany.isConsumer
              ? 'Pyydämme lisäämään tilinumerosi'
              : 'Pyydämme lisäämään yrityksesi tilinumeron'}
          </Heading>
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            justifyContent={{ lg: 'space-between' }}
            maxWidth="100%"
            alignItems="center"
          >
            <Box>
              {selectedCompany.isConsumer ? (
                <Text>Lisääthän tilinumerosi, jotta saamme siirrettyä ostajien maksut tilillesi.</Text>
              ) : (
                <Text>
                  Pyydämme sinua lisäämään yrityksesi tilinumeron, jotta voimme siirtää ostajien maksut yrityksesi
                  tilille. Tilinumero lisätään vain kerran, minkä jälkeen siirrämme maksut jatkossa samalle tilille.
                </Text>
              )}
            </Box>
            <Flex
              justifyContent="center"
              flexShrink={0}
              ml={{ base: 0, lg: 3 }}
              my={{ base: 3, lg: 0 }}
            >
              <Button onClick={() => setIsModalOpen(true)}>
                {selectedCompany.isConsumer ? 'Lisää tilinumerosi' : 'Lisää tilinumero'}
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
