'use client';

import React from 'react';
import { Box, Flex, Heading, Text } from '@mezzoforte/forge';
import { usePathname } from 'next/navigation';
import { Container } from '@/components/Container/Container';
import { NextLinkButton } from '@/components/Link/LinkButton';
import { identificationService } from '@/features/Identification/identification-service';

export interface MissingIdentificationNotificationProps {
  readonly isIdentificationMissing?: boolean;
}

export function MissingIdentificationNotification({ isIdentificationMissing }: MissingIdentificationNotificationProps) {
  const pathname = usePathname();

  if (!isIdentificationMissing || pathname === null) {
    return null;
  }

  return (
    <Box
      py={3}
      backgroundColor="backgroundWarning"
      data-test="missing-identification-notification"
    >
      <Container>
        <Heading
          variant="h4"
          as="h2"
        >
          Tunnistaudu vielä pankkitunnuksilla
        </Heading>
        <Flex flexDir={{ base: 'column', lg: 'row' }}>
          <Text>
            Ennen kuin voit tehdä tarjouksia, tunnistaudu vielä pankkitunnuksilla tai mobiilivarmenteella.
            Huutokaupat.com on Suomen turvallisin kauppapaikka, varmistamme sekä ostajiemme että myyjiemme
            henkilöllisyyden taataksemme palvelun luotettavuuden.
          </Text>
          <Flex
            justifyContent="center"
            flexShrink={0}
            ml={{ base: 0, lg: 3 }}
            my={{ base: 3, lg: 0 }}
          >
            <NextLinkButton
              href={identificationService.getIdentificationUrl('registration', pathname)}
              variant="primary"
            >
              Tunnistaudu
            </NextLinkButton>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
