import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

export const useIsPreviewRoute = () => {
  const pathname = usePathname();
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    const isPreviewUrl = (pathname: string | null) =>
      !!pathname && [/^\/esikatsele\/.*\/?/].some((patt) => pathname.match(patt));
    setIsPreview(isPreviewUrl(pathname));
  }, [pathname]);

  return isPreview;
};
