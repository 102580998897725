import React from 'react';
import { Box, Flex, Heading, Text } from '@mezzoforte/forge';
import { Container } from '@/components/Container/Container';
import { NextLinkButton } from '@/components/Link/LinkButton';
import { formatDateWithWeekday } from '@/util/date';

export interface EndedEntriesNotificationProps {
  readonly consumerEntryNeedsApprovalBefore?: string | null;
}

export function EndedEntriesNotification({ consumerEntryNeedsApprovalBefore }: EndedEntriesNotificationProps) {
  if (
    !consumerEntryNeedsApprovalBefore ||
    // Cannot use useLocation from react-router-dom
    // because this component is rendered in a portal on non-react-router-dom pages (sidebar-app)
    window.location.pathname === '/oma-sivu/ilmoitukset'
  ) {
    return null;
  }

  return (
    <Box
      py={3}
      backgroundColor="backgroundSuccess"
      data-test="ended-entries-notification"
    >
      <Container>
        <Heading
          variant="h4"
          mb={3}
        >
          Huutokauppasi on päättynyt
        </Heading>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
          maxWidth="100%"
          alignItems="center"
        >
          <Box>
            <Text>
              Käythän hyväksymässä tai hylkäämässä korkeimman tarjouksen mahdollisimman pian, viimeistään{' '}
              {formatDateWithWeekday(new Date(consumerEntryNeedsApprovalBefore))}.
            </Text>
          </Box>
          <Flex
            justifyContent="center"
            flexShrink={0}
            ml={{ base: 0, lg: 3 }}
            my={{ base: 3, lg: 0 }}
          >
            <NextLinkButton
              variant="highlight"
              href="/oma-sivu/ilmoitukset"
            >
              Siirry hyväksymään
            </NextLinkButton>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
