import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  ButtonProps,
  Input,
  Stack,
} from '@mezzoforte/forge';
import { Deferred } from '@/util/deferred';

interface DialogContextValue {
  readonly alert: (title: string, message: React.ReactNode) => Promise<void>;
  readonly confirm: (
    title: string,
    message: React.ReactNode,
    submitText?: string,
    submitVariant?: ButtonProps['variant'],
    cancelText?: string
  ) => Promise<boolean>;
  readonly prompt: (
    title: string,
    message: React.ReactNode,
    placeholder?: string,
    submitText?: string,
    submitVariant?: ButtonProps['variant']
  ) => Promise<string | undefined>;
}

export const DialogContext = createContext<DialogContextValue>({
  alert: async () => Promise.resolve(),
  confirm: async () => Promise.resolve(false),
  prompt: async () => Promise.resolve(undefined),
});

/**
 * Provides simple alert/confirm dialogs.
 */
export function DialogProvider({ children }: PropsWithChildren): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogType, setDialogType] = useState<'alert' | 'confirm' | 'prompt'>('alert');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState<React.ReactNode>(null);
  const [dialogSubmitText, setDialogSubmitText] = useState('');
  const [dialogSubmitVariant, setDialogSubmitVariant] = useState<ButtonProps['variant']>('primary');
  const [dialogCancelText, setDialogCancelText] = useState('');
  const [dialogPromptPlaceholder, setDialogPromptPlaceholder] = useState('');
  const [dialogPromptInput, setDialogPromptInput] = useState('');
  const alertDeferredRef = useRef<Deferred<void>>();
  const confirmDeferredRef = useRef<Deferred<boolean>>();
  const promptDeferredRef = useRef<Deferred<string | undefined>>();

  const alertContext = useMemo(
    (): DialogContextValue => ({
      async alert(title, message) {
        setIsOpen(true);
        setDialogType('alert');
        setDialogTitle(title);
        setDialogMessage(message);

        alertDeferredRef.current = new Deferred<void>();

        return alertDeferredRef.current.promise;
      },

      async confirm(title, message, submitText = 'OK', submitVariant = 'primary', cancelText = 'Peruuta') {
        setIsOpen(true);
        setDialogType('confirm');
        setDialogTitle(title);
        setDialogMessage(message);
        setDialogSubmitText(submitText);
        setDialogSubmitVariant(submitVariant);
        setDialogCancelText(cancelText);

        confirmDeferredRef.current = new Deferred<boolean>();

        return confirmDeferredRef.current.promise;
      },

      async prompt(title, message, placeholder = '', submitText = 'OK', submitVariant = 'primary') {
        setIsOpen(true);
        setDialogType('prompt');
        setDialogTitle(title);
        setDialogMessage(message);
        setDialogSubmitText(submitText);
        setDialogSubmitVariant(submitVariant);
        setDialogCancelText('Peruuta');
        setDialogPromptPlaceholder(placeholder);
        setDialogPromptInput('');

        promptDeferredRef.current = new Deferred<string | undefined>();

        return promptDeferredRef.current.promise;
      },
    }),
    []
  );

  const onConfirm = useCallback(() => {
    setIsOpen(false);

    switch (dialogType) {
      case 'alert':
        alertDeferredRef.current?.resolve();
        break;

      case 'confirm':
        confirmDeferredRef.current?.resolve(true);
        break;

      case 'prompt':
        promptDeferredRef.current?.resolve(dialogPromptInput);
        break;

      default:
    }
  }, [dialogType, dialogPromptInput]);

  const onClose = useCallback(() => {
    setIsOpen(false);

    switch (dialogType) {
      case 'alert':
        alertDeferredRef.current?.resolve();
        break;

      case 'confirm':
        confirmDeferredRef.current?.resolve(false);
        break;

      case 'prompt':
        promptDeferredRef.current?.resolve(undefined);
        break;

      default:
    }
  }, [dialogType]);

  return (
    <DialogContext.Provider value={alertContext}>
      <Modal
        isOpen={isOpen}
        onDismiss={onClose}
      >
        <ModalHeader>{dialogTitle}</ModalHeader>
        <ModalBody>
          <Stack>
            {typeof dialogMessage === 'string' ? <Text>{dialogMessage}</Text> : dialogMessage}
            {dialogType === 'prompt' && (
              <Input
                placeholder={dialogPromptPlaceholder}
                value={dialogPromptInput}
                onChange={(event) => setDialogPromptInput(event.target.value)}
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          {dialogType === 'alert' ? (
            <Button
              variant="primary"
              onClick={onClose}
            >
              Sulje
            </Button>
          ) : (
            <ButtonGroup>
              <Button onClick={onClose}>{dialogCancelText}</Button>
              <Button
                variant={dialogSubmitVariant}
                onClick={onConfirm}
              >
                {dialogSubmitText}
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      </Modal>

      {children}
    </DialogContext.Provider>
  );
}

export const useDialog = (): DialogContextValue => useContext(DialogContext);
